import {
  createApi,
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { osName, osVersion } from "react-device-detect";
import { AppState } from "store/store";
import { setDeviceId, exitSession } from "store/slices/app";
import { showError } from "store/slices/layout";
import map from "lodash/map";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASEURL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as AppState).app.auth_token.id;
    const deviceId = (getState() as AppState).app.auth_token.device_id;
    const brandId = (getState() as AppState).user?.selectedBrand?.id;

    headers.set("X-App-ID", process.env.REACT_APP_ID || "");
    headers.set("X-App-Version", process.env.REACT_APP_VERSION || "");
    headers.set("X-OS-Name", osName);
    headers.set("X-OS-Version", osVersion);
    if (token) {
      headers.set("X-Admin-AuthToken", token);
    }
    if (deviceId) {
      headers.set("X-Device-ID", deviceId);
    }
    if (brandId) {
      headers.set("X-Brand-ID", brandId);
    }
    return headers;
  },
});

const baseQueryWithClientManagement: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error) {
    const data = result.error.data as any;
    if (result.error.status === 400) {
      if (data && data.error_code === "BAD-DEVICE-ID") {
        const registerResult = await baseQuery(
          { url: "/v1/register", method: "POST" },
          api,
          extraOptions
        );
        if (registerResult.data) {
          api.dispatch(setDeviceId(registerResult.data));
          result = await baseQuery(args, api, extraOptions);
        }
      } else {
        api.dispatch(showError(data));
      }
    } else if (result.error.status === 401) {
      api.dispatch(exitSession());
    } else {
      api.dispatch(showError(data));
    }
  }
  return result;
};

export const api = createApi({
  baseQuery: baseQueryWithClientManagement,
  tagTypes: ["Brands", "ItemCategory", "OptionCategory"],
  endpoints: (builder) => ({
    login: builder.mutation<any, LoginRequest>({
      query: (credentials) => ({
        url: "/v1/login",
        method: "POST",
        body: credentials,
      }),
      transformResponse: (response: any) => response.result,
    }),
    logout: builder.mutation<any, void>({
      query: () => ({
        url: "/v1/logout",
        method: "POST",
      }),
      transformResponse: (response: any) => response.result,
      invalidatesTags: ["ItemCategory", "OptionCategory"],
    }),
    getUser: builder.query<AuthUser, void>({
      query: () => ({
        url: "/v1/users/auth",
      }),
      transformResponse: (response: any) => response.result.user,
    }),
    getBrands: builder.query<any, void>({
      query: () => ({
        url: "/v1/users/brands",
      }),
      transformResponse: (response: any) => response.result.brands,
      providesTags: [{ type: "Brands", id: "LIST" }],
    }),
    getOrders: builder.query<Order[], OrdersRequest>({
      query: (params) => ({
        url: "/v1/orders/header",
        params: params,
      }),
      transformResponse: (response: any) => response.result.orders_header,
    }),
    getOrder: builder.query<Order, string>({
      query: (id) => ({
        url: `/v1/orders/${id}/details`,
      }),
      transformResponse: (response: any) => response.result.order_details,
    }),
    getMenuItemCategories: builder.query<MenuItemCategory[], void>({
      query: () => ({
        url: `/v1/menu/item/categories`,
      }),
      transformResponse: (response: any) =>
        response.result.menu_item_categories,
      providesTags: ["ItemCategory"],
    }),
    getMenuItems: builder.query<MenuItemList[], MenuItemListRequest>({
      query: (params) => ({
        url: `/v1/menu/items`,
        params: params,
      }),
      transformResponse: (response: any) => response.result.menu_items,
    }),

    uploadMenuItemImage: builder.mutation<any, FormData>({
      query: (request) => ({
        url: `/v1/menu/item/upload-image`,
        method: "POST",
        body: request,
      }),
      transformResponse: (response: any) => response.result,
    }),
    getMenuItem: builder.query<MenuItem, string>({
      query: (id) => ({
        url: `/v1/menu/items/${id}`,
      }),
      transformResponse: (response: any) => response.result.menu_item,
    }),
    getMenuItemOptionCategory: builder.query<MenuItemOptionCategory, string>({
      query: (id) => ({
        url: `/v1/menu/options/categories/${id}`,
      }),
      transformResponse: (response: any) =>
        response.result.menu_option_category,
      providesTags: ["OptionCategory"],
    }),
    saveMenuItem: builder.mutation<MenuItem, AddMenuItemRequest>({
      query: (request) => ({
        url: "/v1/menu/items",
        method: "POST",
        body: request,
      }),
      transformResponse: (response: any) => response.result,
    }),
    updateMenuItem: builder.mutation<
      MenuItem,
      { id: string; body: AddMenuItemRequest }
    >({
      query: (request) => ({
        url: `/v1/menu/items/${request.id}`,
        method: "PUT",
        body: request.body,
      }),
      transformResponse: (response: any) => response.result,
    }),
    moveMenuItem: builder.mutation<
      MenuItem,
      { id: string; body: any }
    >({
      query: (request) => ({
        url: `/v1/menu/items/${request.id}/move`,
        method: "PUT",
        body: request.body,
      }),
      transformResponse: (response: any) => response.result,
    }),
    updateMenuItemOption: builder.mutation<
      MenuItemOptionCategory,
      { id: string; body: AddMenuItemOptionCategoryRequest }
    >({
      query: (request) => ({
        url: `/v1/menu/options/categories/${request.id}`,
        method: "PUT",
        body: request.body,
      }),
      transformResponse: (response: any) => response.result,
    }),
    deleteMenuItem: builder.mutation<any, string>({
      query: (id) => ({
        url: `/v1/menu/items/${id}`,
        method: "DELETE",
      }),
    }),
    deleteMenuItemCategory: builder.mutation<any, string>({
      query: (id) => ({
        url: `/v1/menu/items/categories/${id}`,
        method: "DELETE",
      }),
    }),
    changeMenuItemAvailability: builder.mutation<any, string>({
      query: (id) => ({
        url: `/v1/menu/items/${id}/availability`,
        method: "PUT",
      }),
    }),
    changeMenuOptionAvailability: builder.mutation<any, string>({
      query: (id) => ({
        url: `/v1/menu/options/${id}/availability`,
        method: "PUT",
      }),
    }),
    getMenuOptionCategories: builder.query<MenuOptionCategory[], void>({
      query: () => ({
        url: "/v1/menu/options/categories",
      }),
      transformResponse: (response: any) =>
        response.result.menu_option_categories,
    }),
    getMenuOptions: builder.query<MenuOptionList[], MenuOptionListRequest>({
      query: (params) => ({
        url: "/v1/menu/options",
        params: params,
      }),
      transformResponse: (response: any) => response.result.menu_options,
    }),
    getWeeklyTimes: builder.query<WeeklyTimes, void>({
      query: () => ({
        url: "/v1/settings/weekly-times",
      }),
      transformResponse: (response: any) => {
        return response.result.weekly_times;
      },
    }),
    updateWeeklyTimes: builder.mutation<any, { weekly_times: WeeklyTimes }>({
      query: (request) => ({
        url: "/v1/settings/weekly-times",
        method: "POST",
        body: request,
      }),
      transformResponse: (response: any) => response.result.weekly_times,
    }),
    menuOptionAutocomplete: builder.mutation<any, string>({
      query: (keyword) => ({
        url: `/v1/menu/autocomplete/items`,
        params: {
          keywords: keyword,
        },
      }),
      transformResponse: (response: any) =>
        response.result.autocomplete_menu_items,
    }),
    getPromos: builder.query<Promo[], PromosListRequest>({
      query: (params) => ({
        url: "/v1/promos",
        params: params,
      }),
      transformResponse: (response: any) => response.result.promos,
    }),
    updatePromo: builder.mutation<any, string>({
      query: (id) => ({
        url: `/v1/promos/${id}`,
        method: "PUT",
      }),
      transformResponse: (response: any) => response.result.weekly_times,
    }),
    addPromo: builder.mutation<any, AddPromoItemRequest>({
      query: (request) => ({
        url: `/v1/promos`,
        method: "POST",
        body: request,
      }),
      transformResponse: (response: any) => response.result.weekly_times,
    }),
    deletePromoItem: builder.mutation<any, string>({
      query: (id) => ({
        url: `/v1/promos/${id}`,
        method: "DELETE",
      }),
    }),

    //delivery ranges
    getDeliveryRanges: builder.query<Rider[], void>({
      query: () => ({
        url: "/v1/settings/delivery-ranges",
      }),
      transformResponse: (response: any) => response.result.delivery_ranges,
    }),

    addPooneyAccount: builder.mutation<
      any,
      { client_id: string; client_secret: string }
    >({
      query: (request) => ({
        url: `/v1/settings/external-runners/account`,
        method: "POST",
        body: request,
      }),
    }),
    addDeliveryRange: builder.mutation<any, AddDeliveryRequest>({
      query: (request) => ({
        url: `/v1/settings/delivery-ranges`,
        method: "POST",
        body: request,
      }),
    }),
    deleteDeliveryRange: builder.mutation<any, string>({
      query: (id) => ({
        url: `/v1/settings/delivery-ranges?delivery_range_id=${id}`,
        method: "DELETE",
      }),
    }),

    updateMinOrderPrice: builder.mutation<any, { min_order_price: number }>({
      query: (request) => ({
        url: `/v1/settings/min-order-price`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: [{ type: "Brands", id: "LIST" }],
    }),
    updatePreparationTime: builder.mutation<
      any,
      { order_preparation_time: number }
    >({
      query: (request) => ({
        url: `/v1/settings/order-preparation-time`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: [{ type: "Brands", id: "LIST" }],
    }),
    //riders
    getRiders: builder.query<Rider[], any>({
      query: (params) => ({
        url: "/v1/settings/runners",
        params: {
          only_available: params.available,
          brand_id: params.brand_id
        },
      }),
      transformResponse: (response: any) => response.result.runners,
    }),

    addRider: builder.mutation<any, AddRiderRequest>({
      query: (request) => ({
        url: `/v1/settings/runners`,
        method: "POST",
        body: request,
      }),
    }),
    deleteRider: builder.mutation<any, string>({
      query: (id) => ({
        url: `/v1/settings/runners/${id}`,
        method: "DELETE",
      }),
    }),
    updateRider: builder.mutation<any, Rider>({
      query: (request) => ({
        url: `/v1/settings/runners/${request.id}`,
        method: "PUT",
        body: request,
      }),
    }),
    updateRiderAvailability: builder.mutation<any, string>({
      query: (id) => ({
        url: `/v1/settings/runners/${id}/availability`,
        method: "PUT",
      }),
    }),
    ///////////////////
    acceptOrder: builder.mutation<Order, { id: string; body: any }>({
      query: (request) => ({
        url: `/v1/orders/${request.id}/accept`,
        method: "PUT",
        body: request.body,
      }),
      transformResponse: (response: any) => response.result,
    }),
    deliveryOrder: builder.mutation<Order, string>({
      query: (id) => ({
        url: `/v1/orders/${id}/on-delivery`,
        method: "PUT",
      }),
      transformResponse: (response: any) => response.result,
    }),
    refundOrder: builder.mutation<Order, { id: string; body: any }>({
      query: (request) => ({
        url: `/v1/orders/${request.id}/refund`,
        method: "PUT",
        body: request.body,
      }),
      transformResponse: (response: any) => response.result,
    }),
    deliveredOrder: builder.mutation<Order, string>({
      query: (id) => ({
        url: `/v1/orders/${id}/delivery`,
        method: "PUT",
      }),
      transformResponse: (response: any) => response.result,
    }),
    rejectOrder: builder.mutation<Order, { id: string; body: any }>({
      query: (request) => ({
        url: `/v1/orders/${request.id}/reject`,
        method: "PUT",
        body: request.body,
      }),
      transformResponse: (response: any) => response.result,
    }),
    setTakeawayAvailability: builder.mutation<void, void>({
      query: () => ({
        url: `/v1/users/takeaway/availability`,
        method: "PUT",
      }),
    }),
    setDeliveryAvailability: builder.mutation<void, void>({
      query: () => ({
        url: `/v1/users/delivery/availability`,
        method: "PUT",
      }),
    }),
    setNotificationsToken: builder.mutation<any, string>({
      query: (token) => ({
        url: `/v1/register/messaging`,
        method: "POST",
        body: {
          messaging_token: token,
        },
      }),
      transformResponse: (response: any) => response.result,
    }),
    getUserStats: builder.query<any, any>({
      query: (params) => ({
        url: "/v1/users/stats",
        params: params,
      }),
      transformResponse: (response: any) => response.result.stats,
    }),
    getQRCode: builder.query<any, any>({
      query: (params) => ({
        url: `/v1/links/qr-code`,
        params: params,
      }),
      transformResponse: (response: any) => response.result.qr_code_url,
    }),
    getOAuthUrl: builder.query<any, string>({
      query: (service) => ({
        url: `/v1/settings/${service}-oauth`,
      }),
      transformResponse: (response: any) => response.result.action_url,
    }),
    setInboundMethod: builder.mutation<any, { service: string; body: any }>({
      query: (request) => ({
        url: `/v1/settings/${request.service}-oauth`,
        method: "POST",
        body: request.body,
      }),
      transformResponse: (response: any) => response.result,
    }),
    getPaymentCard: builder.query<PaymentCard, void>({
      query: () => ({
        url: `/v1/settings/payment-card`,
      }),
      transformResponse: (response: any) => response.result.payment_card,
    }),
    setupPaymentCard: builder.query<string, void>({
      query: () => ({
        url: `/v1/settings/payment-card/setup`,
      }),
      transformResponse: (response: any) =>
        response.result.setup_intent.stripe_client_secret,
    }),
    savePaymentCard: builder.mutation<any, any>({
      query: (request) => ({
        url: `/v1/settings/payment-card/setup`,
        method: "POST",
        body: request,
      }),
      transformResponse: (response: any) => response.result,
    }),
    updateBrand: builder.mutation<any, any>({
      query: (request) => ({
        url: `v1/settings/brand/details`,
        method: "PUT",
        body: request,
      }),
      transformResponse: (response: any) => response.result,
      invalidatesTags: [{ type: "Brands", id: "LIST" }],
    }),
    uploadProfileImage: builder.mutation<any, FormData>({
      query: (request) => ({
        url: `/v1/settings/brand/upload/profile-image`,
        method: "POST",
        body: request,
      }),
      transformResponse: (response: any) => response.result,
      invalidatesTags: [{ type: "Brands", id: "LIST" }],
    }),
    uploadBannerImage: builder.mutation<any, FormData>({
      query: (request) => ({
        url: `/v1/settings/brand/upload/banner-image`,
        method: "POST",
        body: request,
      }),
      transformResponse: (response: any) => response.result,
      invalidatesTags: [{ type: "Brands", id: "LIST" }],
    }),
    getMonthlyFees: builder.query<MonthlyFee[], { year: string }>({
      query: ({ year }) => ({
        url: `/v1/settings/monthly-fees?year=${year}`,
        method: "GET",
      }),
      transformResponse: (response: any) => response.result.monthly_fees,
    }),
    cancelExternalRunner: builder.mutation<any, { id: string; body: string }>({
      query: (request) => ({
        url: `/v1/orders/${request.id}/cancel-external-runner-inquiry`,
        method: "POST",
        body: {
          reason_key: request.body
        },
      }),
      transformResponse: (response: any) => response.result,
    }),
  }),
});

export const {
  //brand
  useUpdateBrandMutation,
  useUploadBannerImageMutation,
  useUploadProfileImageMutation,
  //delivery range
  useAddPooneyAccountMutation,
  useLazyGetDeliveryRangesQuery,
  useGetDeliveryRangesQuery,
  useAddDeliveryRangeMutation,
  useDeleteDeliveryRangeMutation,
  useUpdatePreparationTimeMutation,
  useUpdateMinOrderPriceMutation,
  //riders
  useGetRidersQuery,
  useLazyGetRidersQuery,
  useAddRiderMutation,
  useUpdateRiderMutation,
  useDeleteRiderMutation,
  useUpdateRiderAvailabilityMutation,
  //promotion
  useAddPromoMutation,
  useUpdatePromoMutation,
  useDeletePromoItemMutation,
  useGetPromosQuery,
  //monthlyFee
  useGetMonthlyFeesQuery,
  ///////////////
  useLoginMutation,
  useLogoutMutation,
  useGetUserQuery,
  useGetBrandsQuery,
  useLazyGetBrandsQuery,
  useGetOrdersQuery,
  useGetOrderQuery,
  useGetMenuItemCategoriesQuery,
  useGetMenuItemsQuery,
  useGetMenuItemQuery,
  useGetMenuItemOptionCategoryQuery,
  useUploadMenuItemImageMutation,
  useSaveMenuItemMutation,
  useUpdateMenuItemMutation,
  useUpdateMenuItemOptionMutation,
  useDeleteMenuItemMutation,
  useDeleteMenuItemCategoryMutation,
  useChangeMenuItemAvailabilityMutation,
  useChangeMenuOptionAvailabilityMutation,
  useGetMenuOptionCategoriesQuery,
  useGetMenuOptionsQuery,
  useMenuOptionAutocompleteMutation,
  useGetWeeklyTimesQuery,
  useUpdateWeeklyTimesMutation,
  useAcceptOrderMutation,
  useDeliveryOrderMutation,
  useRefundOrderMutation,
  useDeliveredOrderMutation,
  useRejectOrderMutation,
  useSetDeliveryAvailabilityMutation,
  useSetTakeawayAvailabilityMutation,
  useSetNotificationsTokenMutation,
  useGetUserStatsQuery,
  useGetQRCodeQuery,
  useGetOAuthUrlQuery,
  useSetInboundMethodMutation,
  useGetPaymentCardQuery,
  useSetupPaymentCardQuery,
  useSavePaymentCardMutation,
  useMoveMenuItemMutation,
  useCancelExternalRunnerMutation
} = api;
