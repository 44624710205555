import moment from "moment";
import "moment/locale/it";
import React from "react";
import { useWakeLock } from "react-screen-wake-lock";
import { useGetUserQuery, useGetBrandsQuery } from "services/api";
import { useAppDispatch } from "store/hooks/app";
import { useAuth } from "store/hooks/auth";
import { useUser } from "store/hooks/user";
import { selectBrand, setBrands } from "store/slices/user";
import Router from "./Router";

moment.locale("it");

function App() {
  const auth = useAuth();
  const user = useUser();
  const dispatch = useAppDispatch();
  const { request: requestWakeLock, release: releaseWakeLock } = useWakeLock();

  const {
    isLoading: isGetUserLoading,
    data: authUser,
    refetch: getUser,
  } = useGetUserQuery(undefined, {
    refetchOnMountOrArgChange: true,
    skip: !Boolean(auth),
  });

  const { isLoading: isGetBrandsLoading, data: brands, refetch: getBrands } = useGetBrandsQuery(undefined, { 
    refetchOnMountOrArgChange: true,
    skip: !Boolean(auth) 
  });

  React.useEffect(() => {
    if(brands) {
      dispatch(setBrands(brands));
    }
  }, [brands]);

  React.useEffect(() => {
    if(auth) {
      requestWakeLock();
      getBrands();
    } else {
      releaseWakeLock();
      dispatch(setBrands(null));
    }
  }, [auth]);

  return <Router />;
}

export default App;
