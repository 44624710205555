import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { AppState } from "store/store";

const initialState: User = {
  selectedBrand: null,
  brands: null,
  data: {
    email: null,
  },
  newOrders: [],
  newReservations: []
};

export const userSlice = createSlice({
  initialState,
  name: "user",
  reducers: {
    setUser(state: any, action: PayloadAction<UserData>) {
      state.data.email = action.payload.email;
    },
    setBrands(state: any, action: PayloadAction<Brand[] | null>) {
      //state.selectBrand = action.payload[0];
      if(action.payload) {
        //state.selectedBrand = action.payload[0];
      } else {
        state.selectedBrand = null;
      }
      state.brands = action.payload;
    },
    selectBrand(state: any, action: PayloadAction<Brand | null>) {
      state.selectedBrand = action.payload;
    },
    addNewOrder(state: any, action: PayloadAction<string>) {
      let newOrders = [...state.newOrders];
      newOrders.push(action.payload);
      state.newOrders = newOrders;
    },
    removeNewOrder(state: any, action: PayloadAction<string>) {
      let newOrders = [...state.newOrders];
      const orderId = action.payload;
      const newOrderIndex = newOrders.findIndex((o) => o.order_id === orderId);
      if (newOrderIndex > -1) {
        newOrders.splice(newOrderIndex, 1);
      }
      state.newOrders = newOrders;
    },
    addNewReservation(state: any, action: PayloadAction<string>) {
      let newReservations = [...state.newReservations];
      newReservations.push(action.payload);
      state.newReservations = newReservations;
    },
    removeNewReservation(state: any, action: PayloadAction<string>) {
      let newReservations = [...state.newReservations];
      const reservationId = action.payload;
      const newReservationIndex = newReservations.findIndex((o) => o.tables_reservation_id === reservationId);
      if (newReservationIndex > -1) {
        newReservations.splice(newReservationIndex, 1);
      }
      state.newReservations = newReservations;
    },
  },
});

export const { setUser, setBrands, selectBrand, addNewOrder, removeNewOrder, addNewReservation, removeNewReservation } =
  userSlice.actions;

export const userReducer = userSlice.reducer;

export const selectCurrentUser = (state: AppState) => state.user;
