import type {} from "@mui/x-data-grid/themeAugmentation";
import { createTheme } from "@mui/material/styles";
import { orange } from "@mui/material/colors";

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
  interface BreakpointOverrides {
    xxl: true;
  }
  interface Palette {
    yellow: Palette['primary'];
    white: Palette['primary'];
  }
  interface PaletteOptions {
    yellow?: PaletteOptions['primary'];
    white?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    yellow: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    white: true;
  }
}

const theme = createTheme({
  typography: {
    fontFamily: ["Circular"].join(","),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920,
    },
  },
  palette: {
    primary: {
      main: "#5D246B",
      dark: "#41194a",
      light: "#7d4f88",
    },
    secondary: {
      main: "#55B894",
      dark: "#3b8067",
      light: "#77c6a9",
      contrastText: "#fff",
    },
    error: {
      main: "#E50741",
      dark: "#a0042d",
      light: "#ea3867",
      contrastText: "#fff",
    },
    info: {
      main: "#2F4CDD",
      contrastText: "#fff",
    },
    success: {
      main: "#19D79F",
      contrastText: "#fff",
    },
    text: {
      primary: "#242424",
      secondary: "#525252",
    },
    yellow: {
      main: '#F4E716',
      contrastText: '#5D246B',
    },
    white: {
      main: '#FFF',
      contrastText: "#55B894",
    }
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 50,
          background: "#fff",
          border: "1px solid #E5E5E5",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          fontSize: "2.125rem",
          borderRadius: 30
        },
        colorSecondary: {
          background: "#55B894",
          color: "#fff",
          '&:hover': {
            backgroundColor: "#55B894",
            color: "#fff"
          },
          '&.Mui-focused': {
            backgroundColor: "#55B894",
            color: "#fff"
          },
        }
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            border: 0,
            outline: 0,
          },
          "& .MuiInputLabel-root": {
            "&.Mui-focused": {
              fontSize: 21,
              top: "-18px",
            },
            "&.MuiInputLabel-shrink": {
              fontSize: 21,
              top: "-18px",
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 40,
          textTransform: "none",
          "&.MuiButton-sizeLarge": {
            fontSize: 18,
            padding: "14.5px 35px",
            "& .MuiButton-startIcon>*:nth-of-type(1)": {
              fontSize: 30,
            },
          },
          "&.rounded": {
            borderRadius: 12,
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          //background: "#fff",
          //color: "#5D246B",
          color: '#fff',
          boxShadow: "none",
          "& .MuiToolbar-root": {
            padding: 0,
          },
        },
      },
    },
    /*MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 0,
          fontSize: "1.2rem",
          "& .MuiDataGrid-overlay": {
            height: "20vh !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            borderBottom: "2px solid #E7E7E7",
            fontSize: "1rem",
            "& .MuiDataGrid-columnSeparator": {
              display: "none",
            },
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
          "& .MuiDataGrid-row": {
            //maxHeight: 'fit-content !important',
            "&:hover": {
              "& .table-actions": {
                display: "block",
              },
            },
          },
          "&.striped": {
            "& .MuiDataGrid-row": {
              "&:nth-of-type(even)": {
                background: "#F0F0F0",
              },
            },
          },
          "& .MuiDataGrid-cell": {
            lineHeight: "inherit !important",
            overflow: "visible",
            whiteSpace: "normal",
            textOverflow: "unset",
            padding: "18px 10px",
          },
          "& .MuiDataGrid-cell--withRenderer": {
            display: "block",
          },
          "& .table-actions": {
            display: "none",
          },
        },
      },
    },*/
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 0,
          fontSize: "1.2rem",
          "& .MuiDataGrid-overlay": {
            height: "20vh !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            borderBottom: "2px solid #E7E7E7",
            fontSize: "1rem",
            "& .MuiDataGrid-columnSeparator": {
              display: "none",
            },
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
          "&.striped": {
            "& .MuiDataGrid-row": {
              "&:nth-of-type(even)": {
                background: "#F0F0F0",
              },
            },
          },
          "&.clickable": {
            "& .MuiDataGrid-row": {
              cursor: 'pointer'
            },
          },
          "& .MuiDataGrid-cell--withRenderer": {
            display: "block",
          },
          "& .table-actions": {
            display: "none",
          },
        },
        virtualScrollerRenderZone: {
          position: 'relative'
        },
        virtualScrollerContent: {
          maxHeight: "fit-content !important",
          height: "auto !important",
          minHeight: "52px !important"
        },
        viewport: {
          maxHeight: "fit-content !important"
        },
        row: {
          maxHeight: "fit-content !important",
          minHeight: "52px !important",
          "&:hover": {
            "& .table-actions": {
              display: "block",
            },
          },
        },
        renderingZone: {
          maxHeight: "fit-content !important",
        },
        cell: {
          maxHeight: "fit-content !important",
          lineHeight: "inherit !important",
          overflow: "visible",
          whiteSpace: "normal",
          textOverflow: "unset",
          padding: "18px 10px",
        }
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          color: '#55B894'
        },
        filled: {
          paddingTop: 5,
          paddingBottom: 5,
          paddingRight: "45px !important"
        },
        iconFilled: {
          color: "#fff",
          fontSize: "2.125rem"
        }
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          // boxShadow: '0px 12px 33px rgba(62, 73, 84, 0.08)',
          boxShadow: "0px 10px 49px rgba(0, 0, 0, 0.13)",
          borderRadius: 12,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          "&.fat": {
            height: 8,
          },
          borderRadius: 4,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          "&.alert": {
            "& .MuiDialogTitle-root": {
              background: "#E50741"
            }
          },
          "& .MuiDialogTitle-root": {
            display: "flex",
            alignItems: "center",
            background: "#5D246B",
            color: "#fff"
          },
        },
        paper: {
          borderRadius: 30,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardWarning: {
          background: '#F4E716',
          color: '#5D246B',
          borderRadius: '23.2px 0px 23.2px 23.2px;',
          fontSize: '1.25rem',
          '& .MuiAlert-icon': {
            color: '#5D246B'
          },
          '& .MuiAlertTitle-root': {
            fontWeight: 'bold',
            fontSize: '1.25rem'
          }
        }
      }
    }
  },
});

export default theme;
