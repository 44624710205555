import { configureStore } from '@reduxjs/toolkit';
import { api } from 'services/api';
import { brands } from 'middlewares/brands';
import { appReducer } from 'store/slices/app';
import { userReducer } from 'store/slices/user';
import { layoutReducer } from 'store/slices/layout';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    app: appReducer,
    user: userReducer,
    layout: layoutReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
    .concat(api.middleware, brands),
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;