import React, { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Box, LinearProgress } from "@mui/material";
import { useAuth } from "store/hooks/auth";
import { useUser } from 'store/hooks/user';
import { useAppDispatch } from 'store/hooks/app';
import { selectBrand } from 'store/slices/user';

const Home = lazy(() => import("./routes/home/Index"));
const Marketing = lazy(() => import("./routes/marketing/Index"));
const Support = lazy(() => import("./routes/support/Index"));
const Orders = lazy(() => import("./routes/orders/Index"));
const Reservations = lazy(() => import("./routes/orders/tables/Reservations"));
const Order = lazy(() => import("./routes/orders/Order"));
const Reservation = lazy(() => import("./routes/orders/tables/Details"));
const MenuDishes = lazy(() => import("./routes/menu/Dishes"));
const TablesMenuDishes = lazy(() => import("./routes/menu/tables/Dishes"));
const WeeklyTimes = lazy(() => import("./routes/settings/WeeklyTimes"));
const Promotions = lazy(() => import("./routes/settings/Promotions"));
const Delivery = lazy(() => import("./routes/settings/Delivery"));
const Profile = lazy(() => import("./routes/settings/Profile"));
const PaymentsHistory = lazy(() => import("./routes/settings/PaymentsHistory"));
const InboundMethods = lazy(() => import("./routes/settings/InboundMethods"));
const PaymentMethod = lazy(() => import("./routes/settings/PaymentMethod"));
const TablesSettings = lazy(() => import("./routes/settings/Tables"));
const MenuOptions = lazy(() => import("./routes/menu/Options"));
const Login = lazy(() => import("./routes/auth/Login"));

const AuthRoute = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  const location = useLocation();
  if (!auth) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return children;
};

const SelectBrandRoute = ({ children }: { children: JSX.Element }) => {
  const user = useUser();
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    if(!user.selectedBrand) {
      if(user.brands) {
        dispatch(selectBrand(user.brands[0]));
      }
    }
  }, [user.brands]);
  return user.selectedBrand ? children : <LinearProgress />;
};

const Router = () => (
  <BrowserRouter>
    <Suspense fallback={<LinearProgress />}>
      <Routes>
        <Route
          path="/"
          element={
            <AuthRoute>
              <Orders />
            </AuthRoute>
          }
        />
        <Route
          path="/tavoli"
          element={
            <AuthRoute>
              <Reservations />
            </AuthRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <AuthRoute>
              <Home />
            </AuthRoute>
          }
        />
        <Route
          path="/marketing"
          element={
            <AuthRoute>
              <Marketing />
            </AuthRoute>
          }
        />
        <Route
          path="/supporto"
          element={
            <AuthRoute>
              <Support />
            </AuthRoute>
          }
        />
        <Route
          path="/ordini/:id"
          element={
            <AuthRoute>
              <Order />
            </AuthRoute>
          }
        />
        <Route
          path="/tavoli/:id"
          element={
            <AuthRoute>
              <Reservation />
            </AuthRoute>
          }
        />
        <Route
          path="/menu/piatti"
          element={
            <AuthRoute>
              <SelectBrandRoute>
                <MenuDishes />
              </SelectBrandRoute>
            </AuthRoute>
          }
        />
        <Route
          path="/menu-tavoli/piatti"
          element={
            <AuthRoute>
              <SelectBrandRoute>
                <TablesMenuDishes />
              </SelectBrandRoute>
            </AuthRoute>
          }
        />
        <Route
          path="/menu/opzioni"
          element={
            <AuthRoute>
              <SelectBrandRoute>
                <MenuOptions />
              </SelectBrandRoute>
            </AuthRoute>
          }
        />
        <Route
          path="/impostazioni/orari"
          element={
            <AuthRoute>
              <SelectBrandRoute>
                <WeeklyTimes />
              </SelectBrandRoute>
            </AuthRoute>
          }
        />
        <Route
          path="/impostazioni/delivery"
          element={
            <AuthRoute>
              <SelectBrandRoute>
                <Delivery />
              </SelectBrandRoute>
            </AuthRoute>
          }
        />
        <Route
          path="/impostazioni/tavoli"
          element={
            <AuthRoute>
              <SelectBrandRoute>
                <TablesSettings />
              </SelectBrandRoute>
            </AuthRoute>
          }
        />
        <Route
          path="/impostazioni/profilo"
          element={
            <AuthRoute>
              <SelectBrandRoute>
                <Profile />
              </SelectBrandRoute>
            </AuthRoute>
          }
        />
        <Route
          path="/impostazioni/incasso"
          element={
            <AuthRoute>
              <InboundMethods />
            </AuthRoute>
          }
        />
        <Route
          path="/impostazioni/pagamenti"
          element={
            <AuthRoute>
              <PaymentMethod />
            </AuthRoute>
          }
        />
        <Route
          path="/impostazioni/payments-history"
          element={
            <AuthRoute>
              <PaymentsHistory />
            </AuthRoute>
          }
        />
        <Route
          path="/promozioni"
          element={
            <AuthRoute>
              <SelectBrandRoute>
                <Promotions />
              </SelectBrandRoute>
            </AuthRoute>
          }
        />
        <Route path="/login" element={<Login />} />
      </Routes>
    </Suspense>
  </BrowserRouter>
);

export default Router;
