import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppState } from 'store/store';

const initialState: ClientState = {
  auth_token: {
    device_id: localStorage.getItem('x-device-id'),
    expires: null,
    id: localStorage.getItem('x-token')
  },
  firebase_token: null,
  notifications_enabled: false
};

export const appSlice = createSlice({
  initialState,
  name: 'app',
  reducers: {
    setDeviceId(state, action: PayloadAction<any>) {
      localStorage.setItem('x-device-id', action.payload.result.device_id);
      state.auth_token.device_id = action.payload.result.device_id;
    },
    setClient(state, action: PayloadAction<any>) {
      localStorage.setItem('x-token', action.payload.auth_token.id as string);
      state.auth_token = action.payload.auth_token;
    },
    setFirebaseToken(state, action: PayloadAction<string>) {
      state.firebase_token = action.payload;
    },
    setNotificationsEnabled(state, action: PayloadAction<boolean>) {
      state.notifications_enabled = action.payload;
    },
    exitSession(state) {
      localStorage.removeItem('x-token');
      localStorage.removeItem('firebase-token');
      state.auth_token.expires = null;
      state.auth_token.id = null;
      state.firebase_token = null;
    }
  },
});

export const { setDeviceId, setClient, exitSession, setFirebaseToken, setNotificationsEnabled } = appSlice.actions;

export const appReducer = appSlice.reducer;

export const selectAuth = (state: AppState) => state.app.auth_token.id;
export const selectFirebaseToken = (state: AppState) => state.app.firebase_token;
export const selectNotificationsEnabled = (state: AppState) => state.app.notifications_enabled;
