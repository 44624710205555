import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: Layout = {
  sidebar: false,
  error: false,
  success: false
};

export const layoutSlice = createSlice({
  initialState,
  name: 'layout',
  reducers: {
    toggleSideBar(state:any) {
      state.sidebar = !state.sidebar;
    },
    showError(state:any, action: PayloadAction<any>) {
      state.error = action.payload;
    },
    showSuccess(state:any, action: PayloadAction<any>) {
      state.success = action.payload;
    }
  },
});

export const { toggleSideBar, showError, showSuccess } = layoutSlice.actions;

export const layoutReducer = layoutSlice.reducer;
